export const TEXTAREA_ELEMENT_TAG = 'textarea';

export type TextareaSize = 'small' | 'medium' | 'large' | 'extra-large';

export const TEXTAREA_SIZE_CLASSES: { [key in TextareaSize]: string } = {
  small: 'text-12 px-2',
  medium: 'text-14 px-2',
  large: 'text-16 px-3',
  ['extra-large']: 'text-16 px-4',
};

export const textareaClasses =
  'text-grey-12 aria-invalid:border-red-9 aria-invalid:ring-1 aria-invalid:ring-red-9 w-full rounded border border-grey-5 bg-grey-1 py-2.5 outline-none read-only:cursor-default read-only:border-grey-5 read-only:bg-grey-3 hover:border-grey-7 read-only:hover:border-grey-7 focus:border-blue-9 focus:ring-1 focus:ring-blue-9 focus:hover:border-blue-9 disabled:cursor-not-allowed disabled:opacity-75 disabled:hover:border-grey-5';
