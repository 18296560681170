import { AirActionTrackingLocation } from '@air/analytics';
import { createBoardRoute } from '@air/api/shared-utils';
import { Library } from '@air/api/types';
import { ConfirmationModal } from '@air/component-confirmation-modal';
import { intersection } from 'lodash';
import Router from 'next/router';
import pluralize from 'pluralize';
import { useCallback, useState } from 'react';

import { useCreateDeleteBoardTask } from '~/components/DeleteBoardTasks/hooks/useCreateDeleteBoardTask';
import { useIsUploadingToSelectedBoard } from '~/components/Modals/DeleteBoards/util';
import { Routes } from '~/constants/routes';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { getBoardIdFromPath } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

export interface DeleteBoardModalProps {
  boardIds: string[];
  onDelete?: () => void;
  trackLocation: AirActionTrackingLocation;
  libraryId?: Library['id'];
}

export const DeleteBoardModal = ({
  onClose,
  boardIds,
  onDelete,
  trackLocation,
  libraryId,
}: AirModalProps<DeleteBoardModalProps>) => {
  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });

  const { createDeleteBoardTask } = useCreateDeleteBoardTask();

  const { isUploadingToBoard } = useIsUploadingToSelectedBoard(boardIds);
  const [isPending, setIsPending] = useState(false);
  const store = useAirStore();

  const handleCreateDeleteBoardTask = useCallback(async () => {
    try {
      setIsPending(true);
      await createDeleteBoardTask({
        boardIds,
        libraryId,
        trackLocation,
      });

      const centralizedBoard = centralizedBoardSelector(store.getState());
      const centralizedBoardAncestorIds = centralizedBoard?.ancestors?.map(({ id }) => id) || [];

      const boardIdInUrl = getBoardIdFromPath(window.location.pathname);

      // Check if one of the deleted boards is a board the user is on
      if (boardIdInUrl && boardIds.includes(boardIdInUrl)) {
        // If the board deleted has a parent, send the user to the parent, else home
        Router.push(centralizedBoard?.parentId ? createBoardRoute(centralizedBoard.parentId) : Routes.media.all);
        // Redirect if the route is on a child of the deleted board
      } else if (centralizedBoardAncestorIds.length && intersection(centralizedBoardAncestorIds, boardIds).length) {
        Router.push(Routes.media.all);
      }

      onDelete?.();
      onClose();
    } catch (error) {
      console.error('Failed to create delete board task', error);
    }
    setIsPending(false);
  }, [boardIds, createDeleteBoardTask, libraryId, onClose, onDelete, store, trackLocation]);

  useFetchObjectsPermissions({
    objects: {
      boardIds,
    },
  });

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  return (
    <ConfirmationModal
      ctaPrimaryText="Delete"
      id="delete-board"
      isDangerous
      isDisabled={isUploadingToBoard || isPending}
      isLoading={isUploadingToBoard || isPending}
      isOpen
      onConfirm={handleCreateDeleteBoardTask}
      onDismiss={onClose}
      title={`Delete ${pluralize('board', boardIds.length, true)}?`}
    >
      {isUploadingToBoard && (
        <p className="mb-4 font-medium text-red-9">
          Items are currently being uploaded to this board. The board cannot be deleted until this upload has completed.
        </p>
      )}
      <ul className="mt-2 list-inside list-disc">
        <li className="mb-2 text-14">
          Any sub-boards contained within {pluralize('these', boardIds.length)} {pluralize('board', boardIds.length)}{' '}
          will also be deleted.
        </li>
        <li className="mb-2 text-14">
          Assets that live only in {pluralize('these', boardIds.length)} {pluralize('board', boardIds.length)} will be
          deleted.
        </li>
      </ul>
    </ConfirmationModal>
  );
};
