import type { Board } from '@air/api/types';
import { InlineInput } from '@air/component-inline-input';
import { TreeItem, TreeItemButton, TreeItemProps } from '@air/component-tree';
import { ContextMenu, renderContextMenuItems } from '@air/primitive-context-menu';
import { useBreakpointsContext } from '@air/provider-media-query';
import classNames from 'classnames';
import { isError } from 'lodash';
import { memo } from 'react';
import * as Yup from 'yup';

import { BoardsTreeItemOptions } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTreeItem/BoardsTreeItemOptions';
import { useBoardsTreeItemIsActive } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTreeItem/hooks/useBoardsTreeItemIsActive';
import { useBoardsTreeItemMenus } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTreeItem/hooks/useBoardsTreeItemMenus';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { BoardTitleValidation } from '~/swr-hooks/boards/useCreateNewBoard';
import { useUpdateBoard } from '~/swr-hooks/boards/useUpdateBoard';

const BoardTitleValidationScheme = Yup.object().shape({
  ['title']: BoardTitleValidation,
});

export type BoardsTreeItemContentProps = Pick<TreeItemProps, 'onOpenChange' | 'open'> & {
  board: Board;
};

const validateBoardName = (value: string) => {
  try {
    BoardTitleValidationScheme.validateSync({ title: value });
    return undefined;
  } catch (error) {
    return isError(error) ? error.message : 'Unknown ';
  }
};

export const BoardsTreeItemContent = memo(({ board, onOpenChange, open }: BoardsTreeItemContentProps) => {
  const { goToBoardPage } = useGoToBoardPage();
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { isActive } = useBoardsTreeItemIsActive({ board });

  const {
    updateBoard: { mutate: updateBoard },
  } = useUpdateBoard();

  const { menuOptions, isEditing, onMenuChange, onContextMenuChange, setIsEditing, menuIsOpen } =
    useBoardsTreeItemMenus({ board });

  const isSmallSize = isAboveMediumScreen;
  const active = isActive || menuIsOpen;

  return (
    <ContextMenu
      onOpenChange={onContextMenuChange}
      trigger={
        <TreeItem
          onOpenChange={onOpenChange}
          open={open}
          state={active ? 'active' : 'default'}
          suffix={
            isAboveMediumScreen &&
            !isEditing && (
              <div
                className={classNames(
                  'group-focus-within/treeItem:flex group-hover/treeItem:flex group-active/treeItem:flex',
                  menuIsOpen ? 'flex' : 'hidden',
                )}
              >
                <BoardsTreeItemOptions board={board} menuOptions={menuOptions} onMenuChange={onMenuChange} />
              </div>
            )
          }
        >
          {isEditing ? (
            <InlineInput
              rows={1}
              allowNewLines={false}
              label="Board name"
              data-testid="BOARDS_TREE_ITEM_CONTENT_BOARD_TITLE_EDITABLE_TEXT"
              defaultValue={board.title}
              onSubmit={(value) => {
                setIsEditing(false);
                updateBoard({
                  board,
                  trackLocation: 'side-nav-tree-item',
                  values: { title: value },
                });
              }}
              onCancel={() => setIsEditing(false)}
              className="my-0.5 ml-[-7px]"
              inputClassName="w-full px-1.5 leading-[32px]"
              validate={validateBoardName}
            />
          ) : (
            <TreeItemButton
              onClick={() => {
                goToBoardPage({ board, trackLocation: 'sidebar' });
              }}
              className={isSmallSize ? 'h-8' : 'h-9'}
            >
              {board.title}
            </TreeItemButton>
          )}
        </TreeItem>
      }
    >
      {renderContextMenuItems({ options: menuOptions })}
    </ContextMenu>
  );
});

BoardsTreeItemContent.displayName = 'BoardsTreeItemContent';
