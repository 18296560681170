import { isNil } from 'lodash';
import Router from 'next/router';
import isEqual from 'react-fast-compare';

import { QueryParamNames, QueryType } from '~/constants/search';
import { getCurrentQuery, isLocal, uuidRegExpString } from '~/utils/Url';

const boardIdRegExp = new RegExp(`/b/[^/?]*(${uuidRegExpString}).*`, 'i');
const libraryIdRegExp = new RegExp(`/l/[^/?]*(${uuidRegExpString}).*`, 'i');
const personIdRegExp = new RegExp(`/p/[^/?]*(${uuidRegExpString}).*`, 'i');
const clipIdRegExp = new RegExp(`/c/(${uuidRegExpString}).*`, 'i');
const shortIdRegExp = new RegExp(`/a/([0-9a-zA-Z]{9})`);
const savedFilterIdRegExp = new RegExp(`/saved-filter/[^/?]*(${uuidRegExpString}).*`, 'i');

export const getShortIdFromPath = (pathname: string) => {
  const match = pathname?.match(shortIdRegExp);
  return Array.isArray(match) ? match[1] : undefined;
};

export const getBoardIdFromPath = (pathname: string) => {
  const match = pathname?.match(boardIdRegExp);
  return Array.isArray(match) ? match[1] : undefined;
};

export const getLibraryIdFromPath = (pathname: string) => {
  const match = pathname?.match(libraryIdRegExp);
  return Array.isArray(match) ? match[1] : undefined;
};

export const getPersonIdFromPath = (pathname: string) => {
  const match = pathname?.match(personIdRegExp);
  return Array.isArray(match) ? match[1] : undefined;
};

export const getSavedFilterIdFromPath = (pathname: string) => {
  const match = pathname?.match(savedFilterIdRegExp);
  return Array.isArray(match) ? match[1] : undefined;
};

export const getClipIdFromPath = (pathname: string) => {
  const match = pathname?.match(clipIdRegExp);
  return Array.isArray(match) ? match[1] : undefined;
};

export const getSharePointErrorFromSearch = (urlParams?: string) => {
  const error = new URLSearchParams(urlParams).get('error');
  if (error) {
    const errorReason = JSON.parse(error).extra?.reason || '';
    return errorReason?.includes('No Sharepoint License') || false;
  }
  return false;
};


export const isImportPath = (pathname: string) => {
  return pathname.includes('/import/');
};

export const getPathWithoutQuery = (pathname: string) => pathname.split('?')[0];

export const getQueryWithoutParams = (query: string, paramsToOmit: string[]) => {
  const urlParams = new URLSearchParams(query);
  paramsToOmit.forEach((param) => {
    urlParams.delete(param);
  });

  return urlParams;
};

export const getPathWithOmittedParams = (asPath: string, paramsToOmit: string[]) => {
  const [pathname, query] = asPath.split('?');
  const paramsString = getQueryWithoutParams(query, paramsToOmit).toString();

  return paramsString ? `${pathname}?${paramsString}` : pathname;
};

export const pushWithExistingQuery = ({
  path = Router.asPath,
  newQuery = {},
  method = 'push',
}: {
  path?: string;
  newQuery?: QueryType;
  method?: RouterMethod;
}) => {
  const query = getCurrentQuery();
  const newQueryParams: QueryType = {};
  Object.keys(newQuery).forEach((paramName) => {
    const value = newQuery[paramName as QueryParamNames];
    if (isNil(value)) {
      delete query[paramName];
    } else {
      newQueryParams[paramName as QueryParamNames] = value;
    }
  });

  const queryToSet = { ...query, ...newQueryParams };

  if (!isEqual(queryToSet, getCurrentQuery()) || getPathWithoutQuery(path) !== getPathWithoutQuery(Router.asPath)) {
    const routerFunc = method === 'replace' ? Router.replace : Router.push;
    return routerFunc({ pathname: getPathWithoutQuery(path), query: { ...query, ...newQueryParams } }, undefined, {
      shallow: true,
    });
  }
};

export type RouterMethod = 'push' | 'replace';

export const setQueryParams = (newQuery: QueryType, method: RouterMethod = 'push') =>
  pushWithExistingQuery({ newQuery, method });

export const pushWithNewParams = (pathname: string, urlParams?: URLSearchParams) => {
  const queryString = urlParams?.toString();
  const urlToGo = queryString ? `${pathname}?${queryString}` : pathname;

  return Router.push(urlToGo, undefined, { shallow: true });
};

// plasmic does not work if it detects call for window.top
export const isCypressRun = () =>
  isLocal() && (typeof window !== 'undefined' ? !!window.Cypress || !!window.top?.Cypress : false);

export const isClientSideRun = () => typeof window !== 'undefined';
