import { Close } from '@air/next-icons';
import { memo } from 'react';

import { CompletedAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingSubtitle } from '~/components/FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { AssetRestorationTask, GetCompletedMetadata } from '~/store/tasks/types';

export type RestoreAssetTaskCompletedPanelItemProps = {
  metadata: GetCompletedMetadata<AssetRestorationTask>;
  onClear: () => void;
};

export const RestoreAssetTaskCompletedPanelItem = memo(
  ({ metadata: { assets }, onClear }: RestoreAssetTaskCompletedPanelItemProps) => {
    return (
      <PaneItemTW
        truncate={false}
        avatar={<CompletedAvatar />}
        title={<StatusTrackingTitle>Restored successfully</StatusTrackingTitle>}
        subtitle={
          <StatusTrackingSubtitle>
            {assets.length === 1 ? (
              <>Restored asset {<strong>{assets[0].title}</strong>}</>
            ) : (
              `Restored ${assets.length} assets`
            )}
          </StatusTrackingSubtitle>
        }
        buttons={<PaneButton label="Clear" Icon={Close} onClick={onClear} />}
      />
    );
  },
);

RestoreAssetTaskCompletedPanelItem.displayName = 'RestoreAssetTaskCompletedPanelItem';
