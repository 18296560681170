import { Modal, ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';
import { useAirModal } from '@air/provider-modal';
import { useCallback, useMemo } from 'react';

import { useLibrariesCreateMutation } from '~/components/LibraryBeta/hooks/mutations/useLibrariesCreateMutation';
import { LibrarySettingsModal } from '~/components/LibraryBeta/LibrarySettingsModal/LibrarySettingsModal';
import { useLibraryToasts } from '~/hooks/useLibraryToasts';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useGetCustomFieldColors } from '~/swr-hooks/customFields/useGetCustomFieldColors';
import { convertUnknownToError } from '~/utils/ErrorUtils';
import { getLibraryPrivacyValue } from '~/utils/getLibraryPrivacyValue';

import { LibraryCreateModalForm, LibraryCreateModalFormProps } from './components/LibraryCreateModalForm';

export const LibraryCreateModal = ({ onClose }: AirModalProps) => {
  const { data: colors } = useGetCustomFieldColors();
  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });
  const { showLibraryToast } = useLibraryToasts();
  const {
    librariesCreateMutation: { mutate: librariesCreateMutation, isPending },
  } = useLibrariesCreateMutation();
  const [showLibrarySettingsModal] = useAirModal(LibrarySettingsModal);

  const formColors = useMemo(() => {
    return colors?.data.map((color) => ({ hex: color.backgroundHex, id: color.id, name: color.name }));
  }, [colors?.data]);

  const onCreate = useCallback<LibraryCreateModalFormProps['onCreate']>(
    ({ color, description, icon, privacy, title }) => {
      librariesCreateMutation(
        {
          ...getLibraryPrivacyValue(privacy),
          colorId: color?.id,
          description,
          icon,
          title,
        },
        {
          onError: (error) => showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
          onSuccess: (data) => {
            showLibraryToast({
              message: (
                <>
                  Created <b className="text-jay-200">{title}</b> library.
                </>
              ),
            });

            showLibrarySettingsModal({ defaultTab: 'members', libraryId: data.id });

            onClose?.();
          },
        },
      );
    },
    [librariesCreateMutation, onClose, showLibrarySettingsModal, showLibraryToast],
  );

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  return (
    <Modal data-testid="CREATE_LIBRARY_MODAL" dangerouslyBypassFocusLock isOpen onDismiss={onClose}>
      <div className="mb-3 flex justify-between">
        <ModalTitle>Create new library</ModalTitle>
        <ModalCloseButton className="shrink-0" onClick={onClose} />
      </div>
      <ModalDescription className="mb-4">
        A library is a group of assets and boards that is access controlled.
      </ModalDescription>
      <LibraryCreateModalForm colors={formColors} isLoading={isPending} onCreate={onCreate} />
    </Modal>
  );
};
