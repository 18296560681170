import { Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import { type ComponentProps, type ReactNode, useCallback, useEffect, useState } from 'react';
import invariant from 'tiny-invariant';

export const banner = tailwindVariants({
  base: 'relative flex min-h-[64px] items-center justify-center gap-2 py-4 pl-6 pr-20 text-left md:text-center',
  variants: {
    color: {
      blue: 'bg-blue-2 text-blue-12',
      grey: 'bg-grey-4 text-grey-12',
      red: 'bg-red-3 text-red-12',
      teal: 'bg-teal-4 text-teal-12',
      yellow: 'bg-yellow-2 text-yellow-12',
    },
  },
  defaultVariants: {
    color: 'yellow',
  },
});

export type AlertBannerVariants = VariantProps<typeof banner>;

export type AlertBannerProps = Omit<ComponentProps<'div'>, 'onClick' | 'prefix'> &
  Pick<ComponentProps<'button'>, 'onClick'> &
  AlertBannerVariants & {
    defaultState?: boolean;
    /**
     * Number of days to dismiss the banner for if the user
     * clicks the dismiss button.
     */
    dismissDays?: number;
    id?: string;
    onDismiss?: () => void;
    prefix?: ReactNode;
  };

export const AlertBanner = ({
  children,
  className,
  color,
  dismissDays,
  defaultState = true,
  id,
  onClick,
  onDismiss,
  prefix,
  ...restOfProps
}: AlertBannerProps) => {
  const [isVisible, setIsVisible] = useState(defaultState);

  invariant(id || !dismissDays, 'If dismissDays is provided, an id must also be provided.');
  invariant(dismissDays || !id, 'If an id is provided, dismissDays must also be provided.');

  useEffect(() => {
    /**
     * If the banner has a dismissDays prop, check localStorage to
     * see if the banner should be visible or not.
     */
    if (dismissDays && id) {
      const alertBannerStorageValue = localStorage.getItem(id);

      if (alertBannerStorageValue) {
        const alertBannerStorageDate = new Date(alertBannerStorageValue);
        const alertBannerStorageDatePlusDays = new Date(
          alertBannerStorageDate.setDate(alertBannerStorageDate.getDate() + dismissDays),
        );

        if (alertBannerStorageDatePlusDays > new Date()) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      }
    }
  }, [dismissDays, id]);

  const handleDismiss = useCallback(() => {
    setIsVisible(false);

    if (dismissDays && id) {
      localStorage.setItem(id, new Date().toISOString());
    }

    onDismiss?.();
  }, [dismissDays, id, onDismiss]);

  if (!isVisible) return null;

  return (
    <div className={banner({ color, className })} data-testid="ALERT_BANNER" role="alert" {...restOfProps}>
      <button className="absolute inset-0 size-full cursor-pointer border-0 bg-transparent" onClick={onClick} />

      {!!prefix && <div className="shrink-0">{prefix}</div>}

      <div className="relative z-1 text-14">{children}</div>

      <div className="absolute right-6 top-1/2 -translate-y-1/2">
        <IconButton
          appearance="ghost"
          color="grey"
          data-testid="ALERT_BANNER_CLOSE"
          onClick={handleDismiss}
          icon={Close}
          label="Close banner"
        />
      </div>
    </div>
  );
};
