import { Modal } from '@air/primitive-modal';
import { convertUnknownToError } from '@air/utils-error';
import Router from 'next/router';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  CreateDeleteLibraryTaskParams,
  useCreateDeleteLibraryTask,
} from '~/components/DeleteLibraryTasks/hooks/userCreateDeleteLibraryTask';
import { LibraryDeleteConfirmationModalForm } from '~/components/LibraryBeta/LibraryDeleteConfirmationModal/components/LibraryDeleteConfirmationModalForm';
import { LibraryDeleteConfirmationModalHeader } from '~/components/LibraryBeta/LibraryDeleteConfirmationModal/components/LibraryDeleteConfirmationModalHeader';
import { Routes } from '~/constants/routes';
import { useLibraryToasts } from '~/hooks/useLibraryToasts';
import { centralizedBoardLibrary } from '~/store/centralizedBoard/selectors';
import { getLibraryIdFromPath } from '~/utils/PathUtils';

export type LibraryDeleteConfirmationModalProps = {
  libraryId: string;
  name: string;
};

export const LibraryDeleteConfirmationModal = ({
  libraryId,
  name,
  onClose,
}: AirModalProps<LibraryDeleteConfirmationModalProps>) => {
  const { showLibraryToast } = useLibraryToasts();
  const { createDeleteLibraryTask } = useCreateDeleteLibraryTask();
  const [isPending, setIsPending] = useState(false);
  const currentLibraryInBoardView = useSelector(centralizedBoardLibrary);

  const handleCreateDeleteLibraryTask = useCallback(
    async (params: CreateDeleteLibraryTaskParams) => {
      try {
        setIsPending(true);
        await createDeleteLibraryTask(params);
        const libraryIdInUrl = getLibraryIdFromPath(window.location.pathname);
        if (libraryIdInUrl === libraryId || currentLibraryInBoardView?.id === libraryId) {
          Router.push(Routes.media.all);
        }
        onClose();
      } catch (error) {
        showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' });
      }

      setIsPending(false);
    },
    [createDeleteLibraryTask, currentLibraryInBoardView?.id, libraryId, onClose, showLibraryToast],
  );

  return (
    <Modal data-testid="LIBRARY_DELETE_CONFIRMATION_MODAL" isOpen onDismiss={onClose}>
      <LibraryDeleteConfirmationModalHeader onClose={onClose} />
      <LibraryDeleteConfirmationModalForm
        name={name}
        isPending={isPending}
        onClose={onClose}
        onSubmit={async (values) => {
          if (values.name === name) {
            return handleCreateDeleteLibraryTask({ libraryId });
          }
        }}
      />
    </Modal>
  );
};
