import { Form } from '@air/api/types';
import { InlineInput } from '@air/component-inline-input';
import React, { memo, useCallback, useEffect, useState } from 'react';

import { EditableMultilineTextTriggerButton } from '~/_components/InlineInput/EditableMultilineTextTriggerButton';
import { CONTENT_COLLECTION_FORM_MODAL_EDITABLE_DESCRIPTION } from '~/constants/testIDs';
import { useUpdateForm } from '~/swr-hooks/forms/useUpdateForm';

type FormDescriptionProps = {
  form: Form;
};

export const FormDescription = memo(({ form }: FormDescriptionProps) => {
  const { updateForm } = useUpdateForm();

  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(form.description);

  useEffect(() => {
    setValue(form.description);
  }, [form.description]);

  const onSubmit = useCallback(
    (description: string) =>
      updateForm({
        ...form,
        description,
      }),
    [form, updateForm],
  );

  return (
    <div
      className="relative -ml-2 flex w-full shrink-0"
      data-testid={CONTENT_COLLECTION_FORM_MODAL_EDITABLE_DESCRIPTION}
    >
      <EditableMultilineTextTriggerButton
        onClick={() => setIsEditing(true)}
        className="size-full min-h-[36px] py-1.5 text-16"
        placeholder="Add description here…"
        value={value}
      />
      {isEditing && (
        <div className="absolute inset-0 -ml-px mt-px">
          <InlineInput
            label="Asset description"
            onSubmit={(value) => {
              setIsEditing(false);
              onSubmit(value ?? '');
            }}
            onCancel={() => {
              setValue(form.description);
              setIsEditing(false);
            }}
            onChange={setValue}
            className="h-full"
            inputClassName="font-normal h-full text-16 md:text-16 py-1 text-grey-11"
            defaultValue={value}
            placeholder="Add a description here…"
          />
        </div>
      )}
    </div>
  );
});

FormDescription.displayName = 'FormDescription';
