import { Board } from '@air/api/types';
import { InlineInput } from '@air/component-inline-input';
import { TreeItem, TreeItemButton } from '@air/component-tree';
import { Check, Plus } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { constant } from 'lodash';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useCreateNewBoard } from '~/swr-hooks/boards/useCreateNewBoard';
import { useSubBoardsList } from '~/swr-hooks/boards/useSubBoardsList';
import { canCreateBoard } from '~/utils/permissions/boardPermissions';

export interface BoardListItemProps {
  isBoardSelected?: (board: Pick<Board, 'id'>) => boolean;
  initialBoardId?: string;
  board: Board;
  onBoardSelectChange: (_params: { board: Board; selected: boolean }) => void;
  shouldShowSubBoards?: (boardId: string) => boolean;
  getIsBoardDisabled?: (board: Board) => { isDisabled: true; message: string } | undefined;
}

const SubBoardLabel = 'Create New Sub-Board';

export const BoardSearchListItem = memo(
  ({
    board,
    onBoardSelectChange,
    isBoardSelected,
    initialBoardId,
    shouldShowSubBoards = constant(false),
    getIsBoardDisabled,
  }: BoardListItemProps) => {
    const [showSubBoards, setShowSubBoards] = useState<boolean | null>(null);
    const [isCreatingSubBoard, setIsCreatingSubBoard] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const isSelected = !!isBoardSelected?.(board);
    const isDisabled = getIsBoardDisabled?.(board);
    const { boardPermissions } = useBoardPermissions({ boardId: board.id });

    const {
      data: subBoards,
      isInitialLoading: isLoadingSubBoards,
      hasMore,
      loadNextPage,
    } = useSubBoardsList(board.id, Boolean(showSubBoards));

    useFetchObjectsPermissions({
      objects: {
        boardIds: subBoards.map((board) => board.id),
      },
    });

    const { createNewBoard } = useCreateNewBoard();
    const createBoard = useCallback(
      (boardName: string) =>
        createNewBoard({
          board: {
            title: boardName,
            parentId: board.id,
            libraryId: board.library?.id,
          },
          trackLocation: 'board-select-modal',
          shouldFollow: true,
        }),
      [createNewBoard, board.id, board.library?.id],
    );
    const handleSubBoardCreation = useCallback(
      (boardName?: string) => {
        setIsCreatingSubBoard(false);
        if (boardName) {
          createBoard(boardName);
        }
      },
      [createBoard],
    );

    const draftNewSubBoard = useCallback(() => {
      setIsCreatingSubBoard(true);
      setShowSubBoards(true);
    }, []);

    useEffect(() => {
      const shouldScrollIntoView = initialBoardId === board.id;
      if (isSelected && shouldScrollIntoView && containerRef.current) {
        containerRef.current.scrollIntoView();
      }
    }, [isSelected, initialBoardId, board.id]);

    useEffect(() => {
      const userHasSetShowSubBoardsIntentionally = showSubBoards !== null;
      if (shouldShowSubBoards(board.id) && !userHasSetShowSubBoardsIntentionally) {
        setShowSubBoards(true);
      }
    }, [board.id, shouldShowSubBoards, showSubBoards]);

    const treeElement = (
      <TreeItem
        data-title={board.title}
        onOpenChange={(isOpen) => {
          setShowSubBoards(isOpen);

          if (isOpen)
            onBoardSelectChange({
              board,
              selected: false,
            });
        }}
        className={isSelected ? 'bg-indigo-1' : ''}
        suffix={
          isSelected ? (
            <Check className="flex size-4 shrink-0 items-center text-blue-11" />
          ) : canCreateBoard(boardPermissions) ? (
            <div className="hidden group-focus-within/treeItem:flex group-hover/treeItem:flex group-active/treeItem:flex">
              <Tooltip label={SubBoardLabel} side="right">
                <IconButton
                  data-testid="BOARD_SEARCH_NEW_SUB_BOARD_PLUS_BUTTON"
                  icon={Plus}
                  size="small"
                  appearance="ghost"
                  color="grey"
                  onClick={draftNewSubBoard}
                  label={SubBoardLabel}
                />
              </Tooltip>
            </div>
          ) : null
        }
      >
        <TreeItemButton
          className={!!isDisabled ? 'opacity-60' : ''}
          onClick={() =>
            onBoardSelectChange({
              board,
              selected: !isSelected,
            })
          }
          disabled={!!isDisabled}
        >
          {board.title}
        </TreeItemButton>
      </TreeItem>
    );

    return (
      <div ref={containerRef} data-testid="BOARD_SEARCH_TREE_ITEM" data-title={board.title}>
        {!!isDisabled ? (
          <Tooltip label={isDisabled.message} side="left" sideOffset={0}>
            <div>{treeElement}</div>
          </Tooltip>
        ) : (
          treeElement
        )}

        {!isCreatingSubBoard && showSubBoards && !subBoards.length && !isLoadingSubBoards && (
          <Button onClick={draftNewSubBoard} className="ml-4 px-4" size="small" appearance="ghost" color="grey">
            {SubBoardLabel}
          </Button>
        )}
        {(showSubBoards || isCreatingSubBoard) && (
          <div className="pl-4">
            {isCreatingSubBoard && (
              <InlineInput
                rows={1}
                allowNewLines={false}
                label="Board name"
                autoFocus
                className="my-1 ml-6"
                onSubmit={(value) => {
                  handleSubBoardCreation(value);
                }}
                data-testid="BOARD_SEARCH_NEW_SUB_BOARD_EDITABLE_TEXT"
              />
            )}
            {subBoards.map((s) => {
              return (
                <div key={s.id}>
                  <BoardSearchListItem
                    shouldShowSubBoards={shouldShowSubBoards}
                    initialBoardId={initialBoardId}
                    isBoardSelected={isBoardSelected}
                    onBoardSelectChange={onBoardSelectChange}
                    board={s}
                    getIsBoardDisabled={getIsBoardDisabled}
                  />
                </div>
              );
            })}
            {!isLoadingSubBoards && hasMore && (
              <Button onClick={() => loadNextPage()} className="ml-4 px-4" size="small" appearance="ghost" color="grey">
                Show more
              </Button>
            )}
          </div>
        )}
      </div>
    );
  },
);

BoardSearchListItem.displayName = 'BoardSearchListItem';
