import { ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';

export type LibraryDeleteConfirmationModalHeaderProps = {
  onClose: () => void;
};

export const LibraryDeleteConfirmationModalHeader = ({ onClose }: LibraryDeleteConfirmationModalHeaderProps) => {
  return (
    <header className="flex flex-col gap-1">
      <div className="flex gap-1">
        <ModalTitle className="grow">Delete Library?</ModalTitle>
        <ModalCloseButton className="shrink-0" onClick={onClose} />
      </div>
      <ModalDescription>
        <ul className="mt-2 list-inside list-disc">
          <li className="mb-2 text-14">All boards and sub-boards within this library will be deleted.</li>
          <li className="mb-2 text-14">
            Assets that live <em>only</em> in this library will be deleted.
          </li>
        </ul>
      </ModalDescription>
    </header>
  );
};
