import { Button, ButtonProps } from '@air/primitive-button';
import { tailwindMerge } from '@air/tailwind-variants';
import React, { memo } from 'react';

import Linkify from '~/components/Linkify';

interface EditableMultilineTextTriggerButtonProps extends Omit<ButtonProps, 'children'> {
  placeholder?: string;
  value?: string;
}

export const EditableMultilineTextTriggerButton = memo(
  ({ placeholder, className, style, value, ...restProps }: EditableMultilineTextTriggerButtonProps) => {
    return (
      <Button
        className={tailwindMerge(
          'whitespace-pre-wrap px-2 py-1 text-left text-12 font-normal',
          !!value ? 'text-grey-11' : 'text-grey-10',
          className,
        )}
        appearance="ghost"
        color="grey"
        style={{ wordBreak: 'break-word', ...style }}
        {...restProps}
      >
        <div className="size-full overflow-hidden text-left">
          {value ? (
            <>
              <Linkify>{value}</Linkify>
              {/**
               * This is need to ensure that newlines will always show the proper height
               * Without this, if user types a newline, the height of the button will not change
               */}
              {value.includes('\n') && <>&nbsp;</>}
            </>
          ) : (
            placeholder
          )}
        </div>
      </Button>
    );
  },
);

EditableMultilineTextTriggerButton.displayName = 'EditableMultilineTextTriggerButtonProps';
