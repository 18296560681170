import { ChevronRight } from '@air/next-icons';
import { tailwindMerge } from '@air/tailwind-variants';
import * as RadixContextMenu from '@radix-ui/react-context-menu';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { CONTEXT_MENU_BASE_CLASS_NAME } from './ContextMenu';
import { CONTEXT_MENU_ITEM_BASE_CLASS_NAME, contextMenuItem, ContextMenuItemProps } from './ContextMenuItem';

export type ContextMenuSubProps = Pick<RadixContextMenu.ContextMenuSubProps, 'defaultOpen' | 'onOpenChange' | 'open'> &
  Omit<RadixContextMenu.ContextMenuSubContentProps, 'prefix'> &
  Pick<RadixContextMenu.ContextMenuSubTriggerProps, 'disabled'> &
  Pick<ContextMenuItemProps, 'description' | 'label' | 'prefix' | 'suffix'> & {
    'data-id'?: string;
    description?: ReactNode;
  };

export const ContextMenuSub = ({
  children,
  className,
  defaultOpen,
  description,
  disabled,
  label,
  onOpenChange,
  open,
  prefix,
  suffix,
  'data-id': dataId,
  ...restOfProps
}: ContextMenuSubProps) => {
  const hasDescription = !!description;

  return (
    <RadixContextMenu.Sub defaultOpen={defaultOpen} onOpenChange={onOpenChange} open={open}>
      <RadixContextMenu.SubTrigger
        className={
          hasDescription ? contextMenuItem({ description: hasDescription }) : CONTEXT_MENU_ITEM_BASE_CLASS_NAME
        }
        data-testid="CONTEXT_MENU_SUB_TRIGGER"
        data-id={dataId}
        disabled={disabled}
      >
        {prefix ? (
          <div className={classNames('mr-2 flex shrink-0 items-center gap-1', hasDescription ? 'mt-0.5' : 'mt-0')}>
            {prefix}
          </div>
        ) : null}

        <div className="flex min-w-0 grow flex-col">
          <span className="truncate text-14">{label}</span>
          {hasDescription ? <span className="text-12 text-grey-10">{description}</span> : null}
        </div>

        <div className={classNames('ml-2 flex shrink-0 items-center gap-1', hasDescription ? 'mt-0.5' : 'mt-0')}>
          {suffix}
          <ChevronRight className="size-4" />
        </div>
      </RadixContextMenu.SubTrigger>

      <RadixContextMenu.Portal>
        <RadixContextMenu.SubContent
          className={className ? tailwindMerge(CONTEXT_MENU_BASE_CLASS_NAME, className) : CONTEXT_MENU_BASE_CLASS_NAME}
          data-testid="CONTEXT_MENU_SUB"
          {...restOfProps}
        >
          {children}
        </RadixContextMenu.SubContent>
      </RadixContextMenu.Portal>
    </RadixContextMenu.Sub>
  );
};
