import { Form } from '@air/api/types';
import { InlineInput, InlineInputProps } from '@air/component-inline-input';
import { Button } from '@air/primitive-button';
import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CONTENT_COLLECTION_FORM_MODAL_EDITABLE_TITLE } from '~/constants/testIDs';
import { centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { useUpdateForm } from '~/swr-hooks/forms/useUpdateForm';

type FormTitleProps = {
  form: Form;
};

export const FormTitle = memo(({ form }: FormTitleProps) => {
  const boardTitle = useSelector(centralizedBoardTitleSelector);
  const { updateForm } = useUpdateForm();

  const [isEditing, setIsEditing] = useState(false);
  const defaultValue = form?.title || boardTitle || '';
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onSubmit = useCallback<Required<InlineInputProps>['onSubmit']>(
    async (newTitle) => {
      if (!form) {
        return;
      }

      if (!newTitle) {
        return;
      }

      await updateForm({
        ...form,
        title: newTitle,
      });
    },
    [form, updateForm],
  );

  return (
    <div className="-ml-2" data-testid={CONTENT_COLLECTION_FORM_MODAL_EDITABLE_TITLE}>
      {isEditing ? (
        <InlineInput
          rows={1}
          allowNewLines={false}
          size="large"
          label="Content collection name"
          onSubmit={(value) => {
            setIsEditing(false);
            onSubmit(value);
          }}
          onCancel={() => {
            setValue(defaultValue);
            setIsEditing(false);
          }}
          onChange={setValue}
          inputClassName="-ml-px text-18 md:text-18 font-medium h-10 text-18 leading-[38px] md:leading-[38px]"
          defaultValue={value}
          placeholder="Add your form title"
        />
      ) : (
        <Button
          size="large"
          appearance="ghost"
          color="grey"
          className="w-full justify-start px-2 text-18 font-medium"
          onClick={() => setIsEditing(true)}
        >
          <span className="truncate">{value || defaultValue}</span>
        </Button>
      )}
    </div>
  );
});

FormTitle.displayName = 'FormTitle';
