import { memo } from 'react';

interface InlineErrorProps {
  error: string | undefined;
  'data-testid'?: string;
}

export const InlineError = memo(({ error, 'data-testid': testId }: InlineErrorProps) => {
  if (!error) {
    return null;
  }

  return (
    <p
      data-testid={testId}
      className="absolute -bottom-1.5 right-4 bg-grey-1 px-2 py-0.5 text-10 font-medium text-red-9"
    >
      {error}
    </p>
  );
});

InlineError.displayName = 'InlineError';
