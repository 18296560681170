const regex = /(?:\.([^.]+))?$/;

export const videoFormat = (filename: string) => {
  const result = regex.exec(filename);
  return result && result[1];
};

export const getTimecode = ({
  currentTime,
  frameCount,
  totalTime,
}: {
  currentTime: number;
  frameCount?: number;
  totalTime?: number;
}) => {
  if (isNaN(currentTime) || currentTime <= 0) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
      frames: frameCount && totalTime ? 0 : undefined,
    };
  }

  const hours = Math.floor(currentTime / 3600);
  const minutes = Math.floor((currentTime % 3600) / 60);
  const seconds = Math.floor(currentTime % 60);
  const frames = frameCount && totalTime ? Math.floor((currentTime % 1) * (frameCount / totalTime)) : undefined;

  return {
    hours,
    minutes,
    seconds,
    frames,
  };
};

export const formatTimecodeValue = (value: number) => {
  return String(value).padStart(2, '0');
};
