// The main purpose of this file is simply to prevent duplicate testIDs from existing anywhere in a project.
// If leveraging testIDs within a mocked_module, simply prefix the testID with `MOCK_` via aliasing your import. Example: `import {ID as MOCK_ID} from '~/constants/testIDs';`
// This is a long, unsorted file... and that's okay. Feel free to sort it if you want.
export const _TAB = '_TAB';
export const INFO_TAB = 'INFO_TAB';
export const ACCOUNT_POPOVER_BUTTON = 'ACCOUNT_POPOVER_BUTTON';
export const ACCOUNT_SETTINGS_EMAIL_BLURB = 'ACCOUNT_SETTINGS_EMAIL_BLURB';
export const ACCOUNT_SETTINGS_LINK = 'ACCOUNT_SETTINGS_LINK';
export const ADD_CLIPS_TO_BOARD_MODAL = 'ADD_CLIPS_TO_BOARD_MODAL';
export const ADD_CUSTOM_FIELD_BUTTON = 'ADD_CUSTOM_FIELD';
export const ADD_CUSTOM_FIELD_MINI_BUTTON = 'ADD_CUSTOM_FIELD_MINI_BUTTON';
export const ADD_DISCUSSION_COMMENT_INPUT = 'ADD_DISCUSSION_COMMENT_INPUT';
export const APPEARS_IN_BOARDS_HEADER = 'APPEARS_IN_BOARDS_HEADER';
export const APPLE_SIGN_IN_BUTTON = 'APPLE_SIGN_IN_BUTTON';
export const ASSET_ACTIONS_FAVORITE_BUTTON = 'ASSET_ACTIONS_FAVORITE_BUTTON';
export const ASSET_CARD = 'ASSET_CARD';
export const ASSET_CARD_SUB_MENU = 'ASSET_CARD_SUB_MENU';
export const ASSET_CARD_SUB_MENU_BUTTON = 'ASSET_CARD_SUB_MENU_BUTTON';
export const ASSET_CARD_TITLE = 'ASSET_CARD_TITLE';
export const ASSET_DOWNLOAD_DROPDOWN_MENU_TRIGGER = 'ASSET_DOWNLOAD_DROPDOWN_MENU_TRIGGER';
export const ASSET_MODAL_CLOSE = 'ASSET_MODAL_CLOSE';
export const ASSET_MODAL_HEADER_ELLIPSIS = 'ASSET_MODAL_HEADER_ELLIPSIS';
export const ASSET_MODAL_HEADER_FIELDS_BUTTON = 'ASSET_MODAL_HEADER_FIELDS_BUTTON';
export const ASSET_MODAL_HEADER_IN_INFO = 'ASSET_MODAL_HEADER_IN_INFO';
export const ASSET_MODAL_KEYWORD = 'ASSET_MODAL_KEYWORD';
export const ASSET_MODAL_NEXT = 'ASSET_MODAL_NEXT';
export const ASSET_MODAL_NEXT_DISABLED = 'ASSET_MODAL_NEXT_DISABLED';
export const ASSET_MODAL_PANEL_TITLE = 'ASSET_MODAL_PANEL_TITLE';
export const ASSET_MODAL_PREVIOUS = 'ASSET_MODAL_PREVIOUS';
export const ASSET_MODAL_PREVIOUS_DISABLED = 'ASSET_MODAL_PREVIOUS_DISABLED';
export const ASSET_SHARE_BUTTON_PUBLIC = 'ASSET_SHARE_BUTTON_PUBLIC';
export const ASSET_STATE_TEXT = 'ASSET_STATE_TEXT';
export const ASSET_TITLE = 'ASSET_TITLE';
export const AUTH_LAYOUT_HEADER = 'AUTH_LAYOUT_HEADER';
export const BOARD_ACTIONS_SUBMENU_BUTTON = 'BOARD_ACTIONS_SUBMENU_BUTTON';
export const BOARD_BLURB_CUSTOM_FIELDS = 'BOARD_BLURB_CUSTOM_FIELDS';
export const BOARD_BLURB_DESCRIPTION = 'BOARD_BLURB_DESCRIPTION';
export const BOARD_BLURB_TITLE = 'BOARD_BLURB_TITLE';
export const BOARD_CARD = 'BOARD_CARD';
export const BOARD_CARD_MENU = 'BOARD_CARD_MENU';
export const BOARD_CARD_MENU_BUTTON = 'BOARD_CARD_MENU_BUTTON';
export const BOARD_CARD_THUMBNAIL = 'BOARD_CARD_THUMBNAIL';
export const BOARD_CARD_TITLE = 'BOARD_CARD_TITLE';
export const BOARD_COLLECTION_ASSET_CARD = 'BOARD_COLLECTION_ASSET_CARD';
export const BOARD_COLLECTION_ASSET_CARD_SUB_MENU = 'BOARD_COLLECTION_ASSET_CARD_SUB_MENU';
export const BOARD_COLLECTION_ASSET_CARD_SUB_MENU_BUTTON = 'BOARD_COLLECTION_ASSET_CARD_SUB_MENU_BUTTON';
export const ASSET_CARD_DESCRIPTION = 'ASSET_CARD_DESCRIPTION';
export const ASSET_CARD_DESCRIPTION_EDITABLE_POPOVER = 'ASSET_CARD_DESCRIPTION_EDITABLE_POPOVER';
export const ASSET_CARD_CUSTOM_FIELDS = 'ASSET_CARD_CUSTOM_FIELDS';
export const ASSET_CARD_ADD_CUSTOM_FIELDS = 'ASSET_CARD_ADD_CUSTOM_FIELDS';
export const BOARD_COLLECTION_ASSET_DETAILS_MODAL = 'BOARD_COLLECTION_ASSET_DETAILS_MODAL';
export const ASSET_DETAILS_INPUT = 'ASSET_DETAILS_INPUT';
export const BOARD_COLLECTION_HERO = 'BOARD_COLLECTION_HERO';
export const BOARD_COLLECTION_TITLE = 'BOARD_COLLECTION_TITLE';
export const BOARD_COLLECTION_DESCRIPTION = 'BOARD_COLLECTION_DESCRIPTION';
export const BOARD_COLLECTION_FIELDS_LIST = 'BOARD_COLLECTION_FIELDS_LIST';
export const BOARD_COLLECTION_FIELDS_LIST_ITEM = 'BOARD_COLLECTION_FIELDS_LIST_ITEM';
export const BOARD_COLLECTION_EMPTY_STATE = 'BOARD_COLLECTION_EMPTY_STATE';
export const BOARD_COLLECTION_ACTION_BAR = 'BOARD_COLLECTION_ACTION_BAR';
export const BOARD_COLLECTION_SELECT_FILES = 'BOARD_COLLECTION_SELECT_FILES';
export const ACTION_BAR_SELECT_ALL = 'ACTION_BAR_SELECT_ALL';
export const ACTION_BAR_UPLOAD = 'ACTION_BAR_UPLOAD';
export const ACTION_BAR_EDIT = 'ACTION_BAR_EDIT';
export const ACTION_BAR_DELETE = 'ACTION_BAR_DELETE';
export const ACTION_BAR_SELECTED_DATA = 'ACTION_BAR_SELECTED_DATA';
export const ACTION_BAR_SUBMIT = 'ACTION_BAR_SUBMIT';
export const BOARD_COLLECTION_SUCCESS_STATE = 'BOARD_COLLECTION_SUCCESS_STATE';
export const BOARD_COLLECTION_SUCCESS_STATE_UPLOAD_MORE = 'BOARD_COLLECTION_SUCCESS_STATE_UPLOAD_MORE';
export const BOARD_COLLECTION_SUCCESS_STATE_SIGN_UP = 'BOARD_COLLECTION_SUCCESS_STATE_SIGN_UP';
export const BOARD_CRUMBS = 'board-crumbs';
export const BOARD_META = 'BOARD_META';
export const BOARD_PAGE_TABLE_HEADER = 'BoardPageTableHeader';
export const BOARD_PAGE_TABLE_VIEW = 'TableRow';
export const BOARD_STATS_BAR = 'BOARD_STATS_BAR';
export const BOARDS_PANNEL = 'BOARDS_PANNEL';
export const BULK_EDIT_SINGLE_SELECT_MODAL = 'BULK_EDIT_SINGLE_SELECT_MODAL';
export const BULK_EDIT_MULTI_SELECT_MODAL = 'BULK_EDIT_MULTI_SELECT_MODAL';
export const CHECKOUT_FORM = 'checkout-form';
export const CLEAR_SEARCH_TEXT_BTN = 'CLEAR_SEARCH_TEXT_BTN';
export const CLIP_PREVIEW_IMAGE = 'CLIP_PREVIEW_IMAGE';
export const CLOSE_POPOVER_BUTTON = 'CLOSE_POPOVER_BUTTON';
export const CLOSE_UPLOADS_BUTTON = 'CLOSE_UPLOADS_BUTTON';
export const COLLAPSE_PANE = 'COLLAPSE_PANE';
export const CONFIG_VIEWS_VISIBILITY = 'CONFIG_VIEWS_VISIBILITY';
export const CONFIRM_SAVED_FILTER_DELETE_BUTTON = 'CONFIRM_SAVED_FILTER_DELETE_BUTTON';
export const CONTINUE_WITH_SAML_SSO_BTN = 'CONTINUE_WITH_SAML_SSO_BTN';
export const CONTENT_COLLECTION_FORM_MODAL_EDITABLE_TITLE = 'CONTENT_COLLECTION_FORM_MODAL_EDITABLE_TITLE';
export const CONTENT_COLLECTION_FORM_MODAL_EDITABLE_DESCRIPTION = 'CONTENT_COLLECTION_FORM_MODAL_EDITABLE_DESCRIPTION';
export const CONTENT_COLLECTION_FORM_MODAL_FIELD_NAME = 'CONTENT_COLLECTION_FORM_MODAL_FIELD_NAME';
export const CONTENT_COLLECTION_FORM_MODAL_FIELD_REQUIREMENT_BUTTON =
  'CONTENT_COLLECTION_FORM_MODAL_FIELD_REQUIREMENT_BUTTON';
export const CONTENT_COLLECTION_FORM_MODAL_FIELD_ELLIPSIS_BUTTON =
  'CONTENT_COLLECTION_FORM_MODAL_FIELD_ELLIPSIS_BUTTON';
export const CONTENT_COLLECTION_FORM_MODAL_ADD_FIELD_BUTTON = 'CONTENT_COLLECTION_FORM_MODAL_ADD_FIELD_BUTTON';
export const COPY_URL_BUTTON = 'COPY_URL_BUTTON';
export const COPY_URL_FIELD_TEXT = 'copy-url-field-text';
export const CREATE_BOARD_BUTTON_END = 'CREATE_BOARD_BUTTON_END';
export const CREATE_BOARD_MODAL = 'CREATE_BOARD_MODAL';
export const CREATE_LINK_BUTTON = 'CREATE_LINK_BUTTON';
export const CREATE_LIBRARY_MODAL = 'CREATE_LIBRARY_MODAL';
export const CREATE_NEW_TAG_BTN = 'CREATE_NEW_TAG_BTN';
export const CREATE_TAG_MODAL = 'CREATE_TAG_MODAL';
export const WORKSPACE_NAME = 'WORKSPACE_NAME';
export const CREATE_WORKSPACE_NAME_INPUT = 'CREATE_WORKSPACE_NAME_INPUT';
export const CREATE_WORKSPACE_NAME_STEP_SUBMIT_BUTTON = 'CREATE_WORKSPACE_NAME_STEP_SUBMIT_BUTTON';
export const CREATED_BOARD_LINK = 'CREATED_BOARD_LINK';
export const CURRENT_BOARD_CUSTOM_FIELD = 'CURRENT_BOARD_CF';
export const CURRENT_WORKSPACE_BUTTON = 'CURRENT_WORKSPACE_BUTTON';
export const CURRENT_WORKSPACE_HEADER_INFO = 'CURRENT_WORKSPACE_HEADER_INFO';
export const CURRENT_WORKSPACE_NAV_CREATE_BOARD_BUTTON = 'CURRENT_WORKSPACE_NAV_CREATE_BOARD_BUTTON';
export const CURRENT_WORKSPACE_NAV_SHOW_SORT_LIST_OPTIONS_BUTTON =
  'CURRENT_WORKSPACE_NAV_SHOW_SORT_LIST_OPTIONS_BUTTON';
export const CURRENT_WORKSPACE_NAV_SORT_LIST_OPTIONS_DROPDOWN = 'CURRENT_WORKSPACE_NAV_SORT_LIST_OPTIONS_DROPDOWN';
export const CURRENT_WORKSPACE_NAV_HIDE_TOGGLE_BUTTON = 'CURRENT_WORKSPACE_NAV_HIDE_TOGGLE_BUTTON';
export const CURRENT_WORKSPACE_NAV_SHOW_TOGGLE_BUTTON = 'CURRENT_WORKSPACE_NAV_SHOW_TOGGLE_BUTTON';
export const CUSTOM_FIELD_CARD = 'CUSTOM_FIELD_CARD';
export const CUSTOM_FIELD_DATE = 'CUSTOM_FIELD_DATE';
export const CUSTOM_FIELD_DELETE = 'CUSTOM_FIELD_DELETE';
export const CUSTOM_FIELD_DELETE_OPTION = 'CUSTOM_FIELD_OPTION_DELETE';
export const CUSTOM_FIELD_EDIT_BUTTON = 'CUSTOM_FIELD_EDIT_BUTTON';
export const CUSTOM_FIELD_LIST = 'CUSTOM_FIELD_LIST';
export const CUSTOM_FIELD_PANEL = 'CUSTOM_FIELD_PANEL';
export const CUSTOM_FIELD_MODAL_ADD_NEW_OPTION = 'CUSTOM_FIELD_ADD_NEW_OPTION';
export const CUSTOM_FIELD_MODAL_CANCEL = 'CUSTOM_FIELD_CANCEL';
export const CUSTOM_FIELD_MODAL_OPTION = 'CUSTOM_FIELD_MODAL_OPTION';
export const CUSTOM_FIELD_MODAL_OPTION_COLOR_MENU_TRIGGER = 'CUSTOM_FIELD_MODAL_OPTION_COLOR_MENU_TRIGGER';
export const CUSTOM_FIELD_MODAL_OPTION_COLOR_OPTION = 'CUSTOM_FIELD_MODAL_OPTION_COLOR_OPTION';
export const CUSTOM_FIELD_MODAL_OPTION_INPUT = 'CUSTOM_FIELD_MODAL_OPTION_INPUT';
export const CUSTOM_FIELD_MODAL_SAVE = 'CUSTOM_FIELD_SAVE';
export const CUSTOM_FIELD_PLAIN_TEXT = 'CUSTOM_FIELD_PLAIN_TEXT';
export const CUSTOM_FIELD_SAVE_OPTION = 'CUSTOM_FIELD_OPTION_SAVE';
export const CUSTOM_FIELD_SELECT_COMPONENT = 'CUSTOM_FIELD_SELECT_COMPONENT';
export const CUSTOM_FIELD_SELECT_LABEL = 'CUSTOM_FIELD_SELECT_LABEL';
export const CUSTOM_FIELD_TYPE_INPUT = 'CUSTOM_FIELD_TYPE_INPUT';
export const CUSTOM_FIELD_VALUE_SELECT = 'CUSTOM_FIELD_VALUE_SELECT';
export const CUSTOM_FIELDS_CONTEXT_MENU_EDIT = 'CUSTOM_FIELDS_CONTEXT_MENU_EDIT';
export const CUSTOMIZE_FIELDS_BUTTON = 'CUSTOMIZE_FIELDS_BUTTON';
export const DATE_CREATED_HEADER = 'DateCreatedHeader';
export const DATE_MODIFIED_HEADER = 'DateModifiedHeader';
export const DELETE_ASSETS_AND_BOARDS_TOGGLE = 'DELETE_ASSETS_AND_BOARDS_TOGGLE';
export const DELETE_ASSETS_WARNING = 'DELETE_ASSETS_WARNING';
export const DELETE_BOARDS_TOGGLE = 'DELETE_BOARDS_TOGGLE';
export const DELETE_TAGS_MODAL = 'DELETE_TAGS_MODAL';
export const DIMENSION_BAR_ASPECT_RATIO_BUTTON = 'DIMENSION_BAR_ASPECT_RATIO_BUTTON_';
export const DIMENSION_BAR_HEIGHT_INPUT = 'DIMENSION_BAR_HEIGHT_INPUT';
export const DIMENSION_BAR_INPUT = 'DIMENSION_BAR_INPUT';
export const DIMENSION_BAR_WIDTH_INPUT = 'DIMENSION_BAR_WIDTH_INPUT';
export const DISCUSSION = 'DISCUSSION';
export const BOUNDING_BOX = 'BOUNDING_BOX';
export const DISCUSSIONS_MENU_BTN = 'DISCUSSIONS_MENU_BTN';
export const DISCUSSION_COMMENT_ADD_REPLY = 'DISCUSSION_COMMENT_ADD_REPLY';
export const DISCUSSION_COMMENT_AVATAR = 'DISCUSSION_COMMENT_AVATAR';
export const DISCUSSION_REPLY_COMMENT_AVATAR = 'DISCUSSION_REPLY_COMMENT_AVATAR';
export const DISCUSSION_COMMENT_BODY = 'DISCUSSION_COMMENT_BODY';
export const ANNOTATION_COMMENT_CONTAINER = 'ANNOTATION_COMMENT_CONTAINER';
export const DISCUSSION_COMMENT_MENU_TRIGGER = 'DISCUSSION_COMMENT_MENU_TRIGGER';
export const DISCUSSION_FORM_BUTTON_CANCEL = 'DISCUSSION_FORM_BUTTON_CANCEL';
export const DISCUSSION_FORM_BUTTON_SUBMIT = 'DISCUSSION_FORM_BUTTON_SUBMIT';
export const DISCUSSION_FORM_BUTTONS_CONTAINER = 'DISCUSSION_FORM_BUTTONS_CONTAINER';
export const DISCUSSION_HEADER_TIMESTAMP_PILL = 'DISCUSSION_HEADER_TIMESTAMP_PILL';
export const DISCUSSIONS_LIST = 'DISCUSSIONS_LIST';
export const DUPLICATE_BOARDS_MODAL = 'DUPLICATE_BOARDS_MODAL';
export const DUPLICATED_ITEM_MODAL = 'DUPLICATED_ITEM_MODAL';
export const EDIT_BOARD_INPUT = 'EDIT_BOARD_INPUT';
export const EMAIL_CONFIRMATION_MESSAGE = 'EMAIL_CONFIRMATION_MESSAGE';
export const EMPTY_BOARD = 'EMPTY_BOARD';
export const EMPTY_BOARD_COPY = 'Add content to this board';
export const EMPTY_BOARD_IMG_ALT = 'Illustration of floating file types';
export const EXPIRATION_DATE_INPUT = 'EXPIRATION_DATE_INPUT';
export const EXPIRATION_DATE_LABEL = 'EXPIRATION_DATE_LABEL';
export const EXPIRATION_DATE_TOGGLE = 'EXPIRATION_DATE_TOGGLE';
export const EXPIRATION_DATE_SUBMIT_BUTTON = 'EXPIRATION_DATE_SUBMIT_BUTTON';
export const EXPORT_IMAGES_MODAL = 'EXPORT_IMAGES_MODAL';
export const EXPORT_IMAGES_SUFFIX_INPUT = 'EXPORT_IMAGES_SUFFIX_INPUT';
export const EXT_PREVIEW_BOX = 'EXT_PREVIEW_BOX';
export const FIELDS_TAB = 'FIELDS_TAB';
export const FILE_CARD = 'FILE_CARD';
export const FILE_CARD_SUB_MENU = 'FILE_CARD_SUB_MENU';
export const FILE_CARD_SUB_MENU_BUTTON = 'FILE_CARD_SUB_MENU_BUTTON';
export const FILE_CARD_TITLE = 'FILE_CARD_TITLE';
export const FILE_HEADER = 'file-header';
export const FILTER_OPTION_COLOR = 'color';
export const FILTER_OPTION_CUSTOM_FIELD = 'Custom field';
export const FILTER_OPTION_DATE = 'Date';
export const FILTER_OPTION_OTHER = 'other';
export const FILTER_OPTION_TAG = 'Tags';
export const FILTER_OPTION_TYPE = 'type';
export const FILTER_OPTION_UPLOADER = 'uploader';
export const FOLLOW_BUTTON = 'FOLLOW_BUTTON';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_EMAIL_INPUT = 'FORGOT_PASSWORD_EMAIL_INPUT';
export const FORGOT_PASSWORD_SUBMIT_BUTTON = 'FORGOT_PASSWORD_SUBMIT_BUTTON';
export const GENERATE_CDN_LINK_BUTTON = 'GENERATE_CDN_LINK_BUTTON';
export const GENERATE_CDN_MODAL_FORMAT_SELECT_FIELD = 'GENERATE_CDN_MODAL_FORMAT_SELECT_FIELD';
export const GOOGLE_SIGN_IN_BUTTON = 'GOOGLE_SIGN_IN_BUTTON';
export const GUEST_ACCESS_MODAL = 'GUEST_ACCESS_MODAL';
export const GUEST_BADGE = 'GUEST_BADGE';
export const GUEST_BOARD_COUNT = 'GUEST_BOARD_COUNT';
export const GUEST_ROLE_MENU = 'GUEST_ROLE_MENU';
export const GUEST_UPGRADE_CONFIRMATION_MODAL = 'GUEST_UPGRADE_CONFIRMATION_MODAL';
export const IMAGE_FORMAT_SINGLE_SELECT = 'IMAGE_FORMAT_SINGLE_SELECT';
export const IMAGE_SIZE_SINGLE_SELECT = 'IMAGE_SIZE_SINGLE_SELECT';
export const IMAGE_THEATER = 'image-theater';
export const IMPORT_MODAL = 'IMPORT_MODAL';
export const IMPORT_MODAL_CLOSE_BUTTON = 'IMPORT_MODAL_CLOSE_BUTTON';
export const IMPORTER_PANE = 'IMPORTER_PANE';
export const INFO_PANEL_DESCRIPTION = 'INFO_PANEL_DESCRIPTION';
export const INFO_PANEL_TITLE = 'INFO_PANEL_TITLE';
export const INVALID_WORKSPACE_INVITE = 'INVALID_WORKSPACE_INVITE';
export const INVITE_PEOPLE_MENU_OPTION = 'invite-people';
export const INVITE_PEOPLE_UPGRADE = 'UPGRADE_PLAN_COPY';
export const INVITE_GUEST_MODAL_INPUT = 'INVITE_GUEST_MODAL_INPUT';
export const KANBAN_COLUMN = 'KANBAN_COLUMN';
export const KANBAN_COLUMN_HEADER = 'KANBAN_COLUMN_HEADER';
export const KANBAN_COLUMN_HEADER_ITEM_COUNT = 'KANBAN_COLUMN_HEADER_ITEM_COUNT';
export const KANBAN_COLUMN_SPINNER = 'KANBAN_COLUMN_SPINNER';
export const KANBAN_GROUP_BY_MENU = 'KANBAN_GROUP_BY_MENU';
export const KANBAN_NULL_STATE = 'KANBAN_NULL_STATE';
export const KANBAN_NULL_STATE_ACTION = 'KANBAN_NULL_STATE_ACTION';
export const KANBAN_NULL_STATE_ACTION_CREATE_CUSTOM_FIELD = 'KANBAN_NULL_STATE_ACTION_CREATE_CUSTOM_FIELD';
export const KANBAN_NULL_STATE_TEXT = 'KANBAN_NULL_STATE_TEXT';
export const KANBAN_VIEW_CONTAINER = 'KANBAN_VIEW_CONTAINER';
export const LINK_NOT_AVAILABLE = 'LINK_NOT_AVAILABLE';
export const LIST_SECTION_TITLE = 'list-section-title';
export const LOADING_BIRD = 'LOADING_BIRD';
export const LOADING_SPINNER = 'LOADING_SPINNER';
export const LOG_IN_LINK = 'LOG_IN_LINK';
export const LOGIN_EMAIL_INPUT = 'LOGIN_EMAIL_INPUT';
export const LOGIN_PASSWORD_INPUT = 'LOGIN_PASSWORD_INPUT';
export const LOGIN_SUBMIT_BUTTON = 'LOGIN_SUBMIT_BUTTON';
export const MAIN = 'MAIN';
export const MAIN_NAVIGATION = 'main-navigation';
export const MAINTENANCE_MODE_BANNER_MESSAGE = 'MAINTENANCE_MODE_BANNER_MESSAGE';
export const MAINTENANCE_MODE_CONTAINER = 'MAINTENANCE_MODE_CONTAINER';
export const MEMBERS_LIMIT_REDIRECT = 'MEMBERS_LIMIT_REDIRECT';
export const MEMBERS_BREAKDOWN_MODAL = 'MEMBERS_BREAKDOWN_MODAL';
export const MENTIONS_TIMESTAMP_PILL = 'MENTIONS_TIMESTAMP_PILL';
export const MERGE_TAGS_MODAL = 'MERGE_TAGS_MODAL';
export const MODAL_LABEL = 'MODAL_LABEL';
export const NO_SHARE_LINK = 'NO_SHARE_LINK';
export const NAME_HEADER = 'NameHeader';
export const NEW_ACTION_BUTTON = 'NEW_ACTION_BUTTON';
export const NO_FAVORITES_TITLE = 'NO_FAVORITES_TITLE';
export const NO_SEARCH_RESULTS = 'NO_SEARCH_RESULTS';
export const SAVED_FILTER_NOT_FOUND = 'SAVED_FILTER_NOT_FOUND';
export const NO_TAGS_TITLE = 'NO_TAGS_TITLE';
export const NO_UNUSED_CLIPS_TITLE = 'NO_UNUSED_CLIPS_TITLE';
export const NO_UNTAGGED_CLIPS_TITLE = 'NO_UNTAGGED_CLIPS_TITLE';
export const NOT_FOUND_CONTENT = 'NOT_FOUND_CONTENT';
export const NOTIFICATIONS_NONE = 'NOTIFICATIONS_NONE';
export const AIR_NOTIFICATIONS_BUTTON = 'AIR_NOTIFICATIONS_BUTTON';
export const AIR_NOTIFICATIONS_BUTTON_BADGE = 'AIR_NOTIFICATIONS_BUTTON_BADGE';
export const AIR_NOTIFICATIONS_POPOVER = 'AIR_NOTIFICATIONS_POPOVER';
export const ONBOARDING_MODAL = 'ONBOARDING_MODAL';
export const ONBOARDING_WORKSPACE_INFO_SUBMIT = 'ONBOARDING_WORKSPACE_INFO_SUBMIT';
export const ONBOARDING_PRIMARY_PURPOSE_SELECT = 'ONBOARDING_PRIMARY_PURPOSE_SELECT';
export const ONBOARDING_PRIMARY_PURPOSE_DETAILS_INPUT = 'ONBOARDING_PRIMARY_PURPOSE_DETAILS_INPUT';
export const ONBOARDING_COMPANY_SIZE_SELECT = 'ONBOARDING_COMPANY_SIZE_SELECT';
export const ONBOARDING_REFERRAL_SOURCE_SELECT = 'ONBOARDING_REFERRAL_SOURCE_SELECT';
export const ONBOARDING_REFERRAL_SOURCE_DETAILS_INPUT = 'ONBOARDING_REFERRAL_SOURCE_DETAILS_INPUT';
export const OPEN_EDIT_TAGS_PANEL_BUTTON = 'OPEN_EDIT_TAGS_PANEL_BUTTON';
export const ORGANIZE_YOUR_FILES_MODAL = 'ORGANIZE_YOUR_FILES_MODAL';
export const OUT_OF_STORAGE_BANNER = 'OUT_OF_STORAGE_BANNER';
export const PAID_PLANS_MODAL_TITLE = 'PAID_PLANS_MODAL_TITLE';
export const PAID_SEAT = 'PAID_SEAT';
export const PASSCODE_PROTECTED_SHARE_PAGE_INPUT = 'PASSCODE_PROTECTED_SHARE_PAGE_INPUT';
export const PASSCODE_PROTECTION_INPUT = 'PASSCODE_PROTECTION_INPUT';
export const PASSCODE_PROTECTION_LABEL = 'PASSCODE_PROTECTION_LABEL';
export const PASSCODE_PROTECTION_TOGGLE = 'PASSCODE_PROTECTION_TOGGLE';
export const PASSCODE_PROTECTION_SUBMIT_BUTTON = 'PASSCODE_PROTECTION_SUBMIT_BUTTON';
export const PASSCODE_PROTECTION_UPSELL_BUTTON = 'PASSCODE_PROTECTION_UPSELL_BUTTON';
export const SUBSCRIPTION_BREAKDOWN_ADDITIONAL_STORAGE_CHARGES = 'SUBSCRIPTION_BREAKDOWN_ADDITIONAL_STORAGE_CHARGES';
export const SUBSCRIPTION_BREAKDOWN_PRORATED_CHARGES = 'SUBSCRIPTION_BREAKDOWN_PRORATED_CHARGES';
export const SUBSCRIPTION_BREAKDOWN_TOTAL_PRICE = 'SUBSCRIPTION_BREAKDOWN_TOTAL_PRICE';
export const SUBSCRIPTION_MANAGE_PLAN = 'SUBSCRIPTION_MANAGE_PLAN';
export const SUBSCRIPTION_MANAGE_PLAN_UPDATE_BILLING_CONTACT = 'SUBSCRIPTION_MANAGE_PLAN_UPDATE_BILLING_CONTACT';
export const SUBSCRIPTION_MANAGE_PLAN_UPDATE_BILLING_CYCLE = 'SUBSCRIPTION_MANAGE_PLAN_UPDATE_BILLING_CYCLE';
export const SUBSCRIPTION_MANAGE_PLAN_TITLE = 'SUBSCRIPTION_MANAGE_PLAN_TITLE';
export const SUBSCRIPTION_SUMMARY = 'SUBSCRIPTION_SUMMARY';
export const SUBSCRIPTION_SUMMARY_TITLE = 'SUBSCRIPTION_SUMMARY_TITLE';
export const PLAN_USAGE_METER = 'PLAN_USAGE_METER';
export const PLAN_USAGE_METER_INFO = 'PLAN_USAGE_METER_INFO';
export const PLAN_USAGE_METER_INFO_STORAGE_USED = 'PLAN_USAGE_METER_INFO_STORAGE_USED';
export const PLAN_USAGE_METER_INFO_STORAGE_USED_PERCENTAGE = 'PLAN_USAGE_METER_INFO_STORAGE_USED_PERCENTAGE';
export const PLAN_USAGE_METER_INFO_WORKSPACE_STORAGE = 'PLAN_USAGE_METER_INFO_WORKSPACE_STORAGE';
export const PLAN_USAGE_INFO_GUEST = 'PLAN_USAGE_INFO_GUEST';
export const PLAN_USAGE_INFO_STORAGE = 'PLAN_USAGE_INFO_STORAGE';
export const PLAN_USAGE_INFO_USER = 'PLAN_USAGE_INFO_USER';
export const PLAY_PAUSE_BUTTON = 'PLAY_PAUSE_BUTTON';
export const PROGRESS_BAR = 'PROGRESS_BAR';
export const PROGRESS_BAR_INDICATOR = 'PROGRESS_BAR_INDICATOR';
export const PUBLIC_BOARD_CHILDREN_CONTAINER = 'PUBLIC_BOARD_CHILDREN_CONTAINER';
export const PUBLIC_BOARD_DOWNLOAD_MENU_TRIGGER = 'PUBLIC_BOARD_DOWNLOAD_MENU_TRIGGER';
export const PUBLIC_TOP_BAR = 'PUBLIC_TOP_BAR';
export const PUBLIC_TOP_BAR_LOGO = 'PUBLIC_TOP_BAR_LOGO';
export const PUBLIC_WORKSPACE_NAVIGATION = 'PUBLIC_WORKSPACE_NAVIGATION';
export const PUBLIC_WORKSPACE_NAVIGATION_BUTTON = 'PUBLIC_WORKSPACE_NAVIGATION_BUTTON';
export const REGISTER_AND_JOIN_BUTTON = 'REGISTER_AND_JOIN_BUTTON';
export const REGISTER_BUTTON = 'REGISTER_BUTTON';
export const CLEAR_SELECTED_VALUES_BUTTON = 'CLEAR_SELECTED_VALUES_BUTTON';
export const RESOLUTION_HEADER = 'resolution-header';
export const RETRY_INVITE_BUTTON = 'RETRY_INVITE_BUTTON';
export const SAVED_FILTER_RESET_BUTTON = 'SAVED_FILTER_RESET_BUTTON';
export const SAVED_FILTER_NO_ASSETS_NULL_STATE = 'SAVED_FILTER_NO_ASSETS_NULL_STATE';
export const SAVED_FILTER_NO_CRITERIA_NULL_STATE = 'SAVED_FILTER_NO_CRITERIA_NULL_STATE';
export const SAVED_FILTER_UPDATE_BUTTON = 'SAVED_FILTER_UPDATE_BUTTON';
export const SEARCH_BOARD_TOKEN = 'SEARCH_BOARD_TOKEN';
export const SEARCH_LIBRARY_TOKEN = 'SEARCH_LIBRARY_TOKEN';
export const SEARCH_TOKEN_CLOSE_BTN = 'SEARCH_TOKEN_CLOSE_BTN';
export const SEARCH_CLOSE = 'SEARCH_CLOSE';
export const SEARCH_CONTAINER = 'SEARCH_CONTAINER';
export const SEARCH_INPUT = 'SEARCH_INPUT';
export const SEARCH_INPUT_CONTAINER = 'SEARCH_INPUT_CONTAINER';
export const SEARCH_RESULTS_TEXT = 'SEARCH_RESULTS_TEXT';
export const SEARCH_SUGGESTION = 'SEARCH_SUGGESTION';
export const SEARCH_SUGGESTIONS_CONTAINER = 'SEARCH_SUGGESTIONS_CONTAINER';
export const SELECTED_CHIP = 'SELECTED_CHIP';
export const SELECTION_ACTION_BAR = 'SELECTION_ACTION_BAR';
export const SELECTION_ACTION_BAR_CLEAR_BTN = 'SELECTION_ACTION_BAR_CLEAR_BTN';
export const SELECTION_ACTION_BAR_ITEMS_SELECTED = 'SELECTION_ACTION_BAR_ITEMS_SELECTED';
export const SEND_PASSCODE_BUTTON = 'SEND_PASSCODE_BUTTON';
export const SET_AS_BOARD_THUMBNAIL_BUTTON = 'SET_AS_BOARD_THUMBNAIL_BUTTON';
export const SET_BOARD_THUMBNAIL_MODAL = 'SET_BOARD_THUMBNAIL_MODAL';
export const SHARE_BUTTON = 'share_button';
export const SIGN_UP_EMAIL_INPUT = 'SIGN_UP_EMAIL_INPUT';
export const SIGN_UP_FIRST_NAME_INPUT = 'SIGN_UP_FIRST_NAME_INPUT';
export const SIGN_UP_LAST_NAME_INPUT = 'SIGN_UP_LAST_NAME_INPUT';
export const SIGN_UP_NEXT_BUTTON = 'SIGN_UP_NEXT_BUTTON';
export const SIGN_UP_PASSWORD_INPUT = 'SIGN_UP_PASSWORD_INPUT';
export const SIGN_UP_TERMS_INPUT = 'SIGN_UP_TERMS_INPUT';
export const SIGN_UP_CONTINUE_BUTTON = 'SIGN_UP_CONTINUE_BUTTON';
export const SIZE_HEADER = 'size-header';
export const SORT_BUTTON = 'SORT_BUTTON';
export const SORT_BUTTON_ARROW = 'SORT_BUTTON_ARROW';
export const SORT_BUTTON_TYPE = 'SORT_BUTTON_TYPE';
export const STORAGE_METER_NUMBERS = 'STORAGE_METER_NUMBERS';
export const SUBSCRIPTION_EXPIRATION_BANNER_CANCELLED = 'SUBSCRIPTION_EXPIRATION_BANNER_CANCELLED';
export const SUBSCRIPTION_EXPIRATION_BANNER_EXPIRED = 'SUBSCRIPTION_EXPIRATION_BANNER_EXPIRED';
export const SUBSCRIPTION_EXPIRATION_BANNER_INVALID = 'SUBSCRIPTION_EXPIRATION_BANNER_INVALID';
export const SUBSCRIPTION_EXPIRATION_BANNER_PAYMENT_FAILED = 'SUBSCRIPTION_EXPIRATION_BANNER_PAYMENT_FAILED';
export const TABLE_ASSET_TITLE = 'TABLE_ASSET_TITLE';
export const TABLE_CUSTOM_FIELD_CELL_BUTTON = 'TABLE_CUSTOM_FIELD_CELL_BUTTON';
export const TABLE_CUSTOM_FIELD_NAME_EDITABLE_TEXT = 'TABLE_CUSTOM_FIELD_NAME_EDITABLE_TEXT';
export const TABLE_ROW = 'TableRow';
export const TABLE_ROW_CELL = 'TABLE_ROW_CELL';
export const TAG_CHECKBOX = 'TAG_CHECKBOX';
export const TAG_EDIT_TITLE = 'TAG_EDIT_TITLE';
export const TAG_NAME_INPUT = 'TAG_NAME_INPUT';
export const TAG_OPTION = 'TAG_OPTION';
export const TAG_ROW = 'TAG_ROW_CONTAINER';
export const TAG_ROW_COUNT = 'TAG_ROW_COUNT';
export const TAG_ROW_MENU_BTN = 'TAG_ROW_MENU_BTN';
export const TAG_ROW_NAME = 'TAG_ROW_NAME';
export const TAG_TO_MERGE_INTO_SELECT = 'TAG_TO_MERGE_INTO_SELECT';
export const TAGS_MANAGER_PAGE = 'TAGS_MANAGER_PAGE';
export const TAGS_SELECT = 'TAGS_SELECT';
export const TAGS_TO_MERGE_SELECT = 'TAGS_TO_MERGE_SELECT';
export const TIME_ELAPSED_CLOCK = 'TIME_ELAPSED_CLOCK';
export const TIMESTAMP_INPUT_BUTTON = 'TIMESTAMP_INPUT_BUTTON';
export const TIMESTAMP_MARKER = 'VIDEO_TIMESTAMP_MARKER';
export const TIMESTAMP_MARKER_AVATAR = 'VIDEO_TIMESTAMP_MARKER_AVATAR';
export const COMMENTS_FILTER_MENU = 'COMMENTS_FILTER_MENU';
export const TOAST_ITEM = 'TOAST_ITEM';
export const TOAST_TEXT = 'TOAST_TEXT';
export const TRANSACTION_MODAL_PRIMARY_CTA = 'TRANSACTION_MODAL_PRIMARY_CTA';
export const TRANSACTION_MODAL_SECONDARY_CTA = 'TRANSACTION_MODAL_SECONDARY_CTA';
export const TYPE_HEADER = 'TYPE_HEADER';
export const UPGRADE_CONFIRMATION_MODAL = 'UPGRADE_CONFIRMATION_MODAL';
export const UPGRADE_CONFIRMATION_MODAL_BUTTON = 'UPGRADE_CONFIRMATION_MODAL_BUTTON';
export const UPLOAD_INPUT = 'UPLOAD_INPUT';
export const UPLOAD_VERSIONS_MODAL = 'UPLOAD_VERSIONS_MODAL';
export const UPLOADING_VERSION_SNIPPET_NUMBER = 'UPLOADING_VERSION_SNIPPET_NUMBER';
export const UPLOADING_VERSION_SNIPPET_NAME = 'UPLOADING_VERSION_SNIPPET_NAME';
export const UPLOADER_PANE = 'UPLOADER_PANE';
export const UPLOADER_SELECT = 'UPLOADER_SELECT';
export const UPLOADER_TITLE = 'UPLOADER_TITLE';
export const UPLOADING_ASSET = 'UPLOADING_ASSET';
export const UPLOADING_FILE = 'UPLOADING_FILE';
export const UPSELL_PLAN_BUTTON = 'UPSELL_PLAN_BUTTON';
export const USERS_BILLING_STATUS = 'USERS_BILLING_STATUS';
export const VERIFY_PAGE_FAILURE = 'VERIFY_PAGE_FAILURE';
export const VERIFY_PAGE_LOGGED_OUT = 'VERIFY_PAGE_LOGGED_OUT';
export const VERIFY_PAGE_SUCCESS = 'VERIFY_PAGE_SUCCESS';
export const VERSION_ITEM_MENU = 'VERSION_ITEM_MENU';
export const VERSION_SNIPPET = 'VERSION_SNIPPET';
export const VERSION_SNIPPET_DROPDOWN_BUTTON = 'VERSION_SNIPPET_DROPDOWN_BUTTON';
export const VIDEO = 'VIDEO';
export const VIDEO_CONTROLS_WRAPPER = 'VIDEO_CONTROLS_WRAPPER';
export const VIEW_CONTROLS = 'VIEW_CONTROLS';
export const VIEW_TYPE_MENU_TOGGLE = 'VIEW_TYPE_MENU_TOGGLE';
export const VISIBILITY_TOGGLE = 'VISIBILITY_TOGGLE';
export const VISIBILITY_TOGGLE_HEADER = 'VISIBILITY_TOGGLE_HEADER';
export const VISIBILITY_TOGGLE_SWITCH = 'VISIBILITY_TOGGLE_SWITCH';
export const VOLUME_BUTTON = 'VOLUME_BUTTON';
export const WORKSPACE_NAV_WORKSPACE_BUTTON = 'WORKSPACE_NAV_WORKSPACE_BUTTON';
export const WORKSPACE_PREVIEW_ITEM = 'WORKSPACE_PREVIEW_ITEM';
export const WORKSPACE_REDIRECT = 'WORKSPACE_REDIRECT';
export const WORKSPACE_SELECT_MODAL = 'WORKSPACE_SELECT_MODAL';
export const SEARCH_STICKY_HEADER_CONTAINER = 'SEARCH_STICKY_HEADER_CONTAINER';
export const X_BUTTON = 'X_BUTTON';
export const ADD_FILTER_BUTTON = 'ADD_FILTER_BUTTON';
export const APPLY_FILTERS_BUTTON = 'APPLY_FILTERS_BUTTON';
export const APPLY_SINGLE_FILTER_BTN = 'APPLY_SINGLE_FILTER_BTN';
export const CLEAR_ALL_FILTERS_BUTTON = 'CLEAR_ALL_FILTERS_BUTTON';
export const FILTERS_DROPDOWN = 'FILTERS_DROPDOWN';
export const FILTER_SELECT_BTN = 'FILTER_SELECT_BTN';
export const CLEAR_FILTER_BTN = 'CLEAR_FILTER_BTN';
export const REMOVE_FILTER_BTN = 'REMOVE_FILTER_BTN';
export const FILTER_CARD = 'FILTER_CARD';
export const FILTER_ROW_CHECKBOX = 'FILTER_ROW_CHECKBOX';
export const NEW_FILTERS_BUTTON = 'NEW_FILTERS_BUTTON';
export const SELECT_CHIP = 'SELECT_CHIP';
export const SELECT_OPTION = 'SELECT_OPTION';
export const SINGLE_FILTER_BUTTON = 'SINGLE_FILTER_BUTTON';
export const SINGLE_FILTER_CARD = 'SINGLE_FILTER_CARD';
export const SINGLE_SELECT_OPTION = 'SINGLE_SELECT_OPTION';
export const BANNERS_CONTAINER = 'BANNERS_CONTAINER';
export const CONTINUE_WITH_PASSWORD = 'CONTINUE_WITH_PASSWORD';
export const SAML_SSO_FORM = 'SAML_SSO_FORM';
export const EXTENSION_SELECT = 'EXTENSION_SELECT';
export const COLOR_FILTER_CHECKBOX = 'COLOR_FILTER_CHECKBOX';
export const SINGLE_SELECT_VALUE = 'SINGLE_SELECT_VALUE';
export const SINGLE_FILTER_BUTTON_COUNT = 'SINGLE_FILTER_BUTTON_COUNT';
export const CLEAR_SINGLE_FILTER_BTN = 'CLEAR_SINGLE_FILTER_BTN';
export const SELECT_LOADING = 'SELECT_LOADING';
export const ANNOTATION_LAYER = 'ANNOTATION_LAYER';
export const BOARD_NAME_CELL = 'BOARD_NAME_CELL';
export const PRIVATE_BOARD_PAGE_CONTENT = 'PRIVATE_BOARD_PAGE_CONTENT';
export const ALL_CONTENT_PAGE_CONTENT = 'ALL_CONTENT_PAGE_CONTENT';
export const LIBRARY_PAGE_CONTENT = 'LIBRARY_PAGE_CONTENT';
export const FAST_FILTER_PAGE_CONTENT = 'FAST_FILTER_PAGE_CONTENT';
export const TOGGLE_EDIT_ANNOTATIONS_BUTTON = 'TOGGLE_EDIT_ANNOTATIONS_BUTTON';
export const TOGGLE_SHOW_ANNOTATIONS_BUTTON = 'TOGGLE_SHOW_ANNOTATIONS_BUTTON';
export const BOX_ANNOTATION_BUTTON = 'BOX_ANNOTATION_BUTTON';
export const DRAW_ANNOTATION_BUTTON = 'DRAW_ANNOTATION_BUTTON';
export const CLOSE_ANNOTATION_PANEL_BUTTON = 'CLOSE_ANNOTATION_PANEL_BUTTON';
export const ANNOTATION_UTILS_PANEL = 'ANNOTATION_UTILS_PANEL';
export const UNDO_ANNOTATION_BTN = 'UNDO_ANNOTATION_BTN';
export const REDO_ANNOTATION_BTN = 'REDO_ANNOTATION_BTN';
export const ANNOTATION_COLOR_CHECKBOX = 'ANNOTATION_COLOR_CHECKBOX';
export const ANNOTATION_SIZE_SELECT = 'ANNOTATION_SIZE_SELECT';
export const KEYBOARD_SHORTCUTS_MODAL = 'KEYBOARD_SHORTCUTS_MODAL';
export const KEYBOARD_SHORTCUTS_BUTTON = 'KEYBOARD_SHORTCUTS_BUTTON';
export const ANNOTATE_ACTION_BUTTON = 'ANNOTATE_ACTION_BUTTON';
export const ANNOTATION_REPLY_BUTTON = 'ANNOTATION_REPLY_BUTTON';
export const COMMENT_METADATA_CONTAINER = 'COMMENT_METADATA_CONTAINER';
export const ANNOTATION_PAGE_NUMBER = 'ANNOTATION_PAGE_NUMBER';
export const ANNOTATION_INDICATOR = 'ANNOTATION_INDICATOR';
export const ANNOTATION_COMMENT_FORM = 'ANNOTATION_COMMENT_FORM';
export const EDIT_COMMENT_FORM = 'EDIT_COMMENT_FORM';
export const REPLY_COMMENT_FORM = 'REPLY_COMMENT_FORM';
export const ANNOTATION_AVATAR_BUTTON = 'ANNOTATION_AVATAR_BUTTON';
export const RESOLVE_DISCUSSION_BUTTON = 'RESOLVE_DISCUSSION_BUTTON';
export const CLOSE_FLOATING_COMMENT_BUTTON = 'CLOSE_FLOATING_COMMENT_BUTTON';
export const REOPEN_DISCUSSION_BUTTON = 'REOPEN_DISCUSSION_BUTTON';
export const SHARE_PRIVATE_CONTENT_MODAL = 'SHARE_PRIVATE_CONTENT_MODAL';
export const INVALID_WORKSPACE_MODAL = 'INVALID_WORKSPACE_MODAL';
export const MENTION_SUGGESTION = 'MENTION_SUGGESTION';
export const MENTION_SUGGESTIONS_CONTAINER = 'MENTION_SUGGESTIONS_CONTAINER';
export const VIEW_CONTROLS_HEADER_TEXT = 'VIEW_CONTROLS_HEADER_TEXT';
export const ASSET_MODAL_PROCESSING_TEXT = 'ASSET_MODAL_PROCESSING_TEXT';
export const SIDEBAR_NAVIGATION_ITEM_MORE = 'SIDEBAR_NAVIGATION_ITEM_MORE';
export const SIDEBAR_NAVIGATION_ITEM_SAVED_FILTERS = 'SIDEBAR_NAVIGATION_ITEM_SAVED_FILTERS';
export const SIDEBAR_NAVIGATION_SAVED_FILTERS_EDIT = 'SIDEBAR_NAVIGATION_SAVED_FILTERS_EDIT';
export const PRIVATE_CONTENT_SHARE_TABS = 'PRIVATE_CONTENT_SHARE_TABS';
export const PRIVATE_CONTENT_SPINNER_TEST_ID = 'PRIVATE_CONTENT_SPINNER';
export const PRIVATE_SHARE_LINK = 'PRIVATE_SHARE_LINK';
export const PRIVATE_SHARE_EDITABLE_LINK = 'PRIVATE_SHARE_EDITABLE_LINK';
export const ANNOUNCEMENT_MODAL_CTA = 'ANNOUNCEMENT_MODAL_CTA';
