import { memo } from 'react';

import { WorkspaceActivityContainer } from '~/components/WorkspaceActivityContainer';

import { PrivateDeleteBoardTasksPanel } from './DeleteBoardTasks/PrivateDeleteBoardTasksPanel';
import { PrivateDeleteLibraryTasksPanel } from './DeleteLibraryTasks/PrivateDeleteLibraryTasksPanel';
import { PrivateDuplicateBoardTasksPanel } from './DuplicateBoardTasks/PrivateDuplicateBoardTasksPanel';
import { ImportManager } from './Import/ImportManager';
import { PrivateRestoreAssetTasksPanel } from './RestoreAssetTasks/PrivateRestoreAssetTasksPanel';
import { PrivateRestoreBoardTasksPanel } from './RestoreBoardTasks/PrivateRestoreBoardTasksPanel';
import { PrivateRestoreLibraryTasksPanel } from './RestoreLibraryTasks/PrivateRestoreLibraryTasksPanel';
import { PrivateUploadManager } from './Upload/PrivateUploadManager';
import { PrivateZippingTasksPanel } from './ZippingTasks/PrivateZippingTasksPanel';

export const PrivateWorkspaceActivityContainer = memo(() => {
  return (
    <WorkspaceActivityContainer>
      <PrivateZippingTasksPanel />
      <PrivateRestoreAssetTasksPanel />
      <PrivateRestoreBoardTasksPanel />
      <PrivateRestoreLibraryTasksPanel />
      <PrivateDuplicateBoardTasksPanel />
      <PrivateDeleteBoardTasksPanel />
      <PrivateDeleteLibraryTasksPanel />
      <PrivateUploadManager />
      <ImportManager />
    </WorkspaceActivityContainer>
  );
});

PrivateWorkspaceActivityContainer.displayName = 'PrivateWorkspaceActivityContainer';
