export const LOCAL_STORAGE_BASE_KEY = `air/${process.env.NEXT_PUBLIC_VERCEL_ENV}`;

export const ALERT_BANNER_MAINTENANCE_MODE = `${LOCAL_STORAGE_BASE_KEY}/alertBanner/maintenanceModeBanner`;
export const ALERT_BANNER_SUBSCRIPTION = `${LOCAL_STORAGE_BASE_KEY}/alertBanner/subscription`;
export const SIDEBAR_COLLAPSED = `${LOCAL_STORAGE_BASE_KEY}/sidebarCollapsed`;
export const SIDEBAR_BOARDS_COLLAPSED = `${LOCAL_STORAGE_BASE_KEY}/sidebar/boards/collapsed`;
export const SIDEBAR_FAVORITES_COLLAPSED = `${LOCAL_STORAGE_BASE_KEY}/sidebar/favorites/collapsed`;
export const SIDEBAR_LIBRARIES_COLLAPSED = `${LOCAL_STORAGE_BASE_KEY}/sidebar/libraries/collapsed`;
export const SIDEBAR_SIZE = `${LOCAL_STORAGE_BASE_KEY}/sidebarSize`;
export const THEME = `${LOCAL_STORAGE_BASE_KEY}/theme`;
export const THUMBNAIL_PREFERENCES = `${LOCAL_STORAGE_BASE_KEY}/thumbnailPreferences`;
export const TURN_ON_NOTIFICATIONS_PROMPT_SHOWN_KEY = 'turnOnNotificationsPromptShown';
export const CARD_SIZE_PREFERENCES = `${LOCAL_STORAGE_BASE_KEY}/cardSizePreference`;
export const NAMED_PEOPLE_SORT = `${LOCAL_STORAGE_BASE_KEY}/namedPeopleSort`;
export const UNNAMED_PEOPLE_SORT = `${LOCAL_STORAGE_BASE_KEY}/unnamedPeopleSort`;
export const WORKSPACE_ACTIVITY_CONTAINER_CORNER = `${LOCAL_STORAGE_BASE_KEY}/workspaceActivityContainerCorner`;
export const VIDEO_TIME_FORMAT = `${LOCAL_STORAGE_BASE_KEY}/videoTimeFormat`;
