import { Spinner } from '@air/primitive-spinner';
import * as RadixSwitch from '@radix-ui/react-switch';
import classNames from 'classnames';
import { forwardRef } from 'react';

export type SwitchProps = RadixSwitch.SwitchProps & {
  isLoading?: boolean;
};

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ className, disabled, isLoading, ...restOfProps }: SwitchProps, ref) => {
    return (
      <RadixSwitch.Root
        ref={ref}
        className={classNames(
          'group block h-4 w-6 shrink-0 rounded-full bg-grey-6 p-0.5 outline-none transition-colors radix-state-checked:bg-blue-9',
          'active:bg-grey-8 active:radix-state-checked:bg-blue-10',
          'hover:bg-grey-8 hover:radix-state-checked:bg-blue-10',
          'focus-visible:ring-2 focus-visible:ring-blue-9 focus-visible:ring-offset-1',
          'disabled:bg-grey-5 disabled:radix-state-checked:bg-blue-3',
          className,
        )}
        data-testid="SWITCH"
        disabled={disabled || isLoading}
        {...restOfProps}
      >
        <RadixSwitch.Thumb className="flex size-3 items-center justify-center rounded-full bg-white transition-transform radix-state-checked:translate-x-2">
          {isLoading && <Spinner className="size-2 text-grey-6 group-radix-state-checked:text-blue-9" />}
        </RadixSwitch.Thumb>
      </RadixSwitch.Root>
    );
  },
);

Switch.displayName = 'Switch';
