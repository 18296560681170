import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentPropsWithRef, forwardRef } from 'react';

import { TEXTAREA_ELEMENT_TAG, TEXTAREA_SIZE_CLASSES, textareaClasses, TextareaSize } from './constants';

export type TextareaProps = Omit<ComponentPropsWithRef<typeof TEXTAREA_ELEMENT_TAG>, 'size'> & {
  size?: TextareaSize;
  hasError?: boolean;
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, rows = 3, size = 'medium', hasError, ...restOfProps }, forwardedRef) => {
    return (
      <textarea
        className={tailwindMerge(
          textareaClasses,
          TEXTAREA_SIZE_CLASSES[size],
          hasError && '!border-red-9 ring-1 !ring-red-9 focus:ring-red-9',
          className,
        )}
        ref={forwardedRef}
        rows={rows}
        {...restOfProps}
      />
    );
  },
);

Textarea.displayName = 'Textarea';
