import { Textarea, TextareaProps } from '@air/primitive-textarea';
import { tailwindMerge } from '@air/tailwind-variants';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { InlineError } from './InlineError';
import { removeNewlines } from './utils';

export interface InlineInputProps
  extends Omit<TextareaProps, 'onSubmit' | 'value' | 'onKeyDown' | 'onKeyUp' | 'onChange' | 'hasError'> {
  error?: string;
  onCancel?: () => void;
  onSubmit?: (value: string) => void;
  onChange?: (value: string) => void;
  defaultValue?: string;
  /** This method should return error string or undefined if value is valid */
  validate?: (value: string) => string | undefined;
  inputClassName?: string;
  label: string;
  allowNewLines?: boolean;
  'data-testid'?: string;
}

export const InlineInput = memo(
  ({
    error,
    onCancel,
    onSubmit,
    onChange,
    defaultValue = '',
    validate,
    className,
    inputClassName,
    label,
    allowNewLines = true,
    ...restProps
  }: InlineInputProps) => {
    const [value, setValue] = useState(defaultValue);
    const [internalError, setInternalError] = useState<string | undefined>();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const errorToShow = error || internalError;
    const hasError = !!errorToShow;

    useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    const submitValue = useCallback(
      (value: string) => {
        if (!hasError) {
          onSubmit?.(value?.trim());
        }
      },
      [hasError, onSubmit],
    );

    const handleEscOnKeyUp = useCallback<Required<TextareaProps>['onKeyUp']>(
      (event) => {
        if (event.key === 'Escape') {
          event.stopPropagation();
          onCancel?.();
        }
      },
      [onCancel],
    );

    const handleBlur = useCallback<Required<TextareaProps>['onBlur']>(() => {
      submitValue(value);
    }, [submitValue, value]);

    const onValueChange = useCallback(
      (fieldValue: string) => {
        setValue(fieldValue);
        onChange?.(fieldValue);

        if (!!validate) {
          setInternalError(validate(fieldValue));
        } else {
          setInternalError(undefined);
        }
      },
      [onChange, validate],
    );

    const onInputChange = useCallback<Required<TextareaProps>['onChange']>(
      (event) => {
        event.target.value = allowNewLines ? event.target.value : removeNewlines(event.target.value);

        onValueChange(event.target.value);
      },
      [onValueChange, allowNewLines],
    );

    const handleKeyDown = useCallback<Required<TextareaProps>['onKeyDown']>(
      (event) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          if (event.shiftKey) {
            if (!allowNewLines) {
              event.preventDefault();
            }
          } else {
            event.preventDefault();
            textAreaRef.current?.blur();
          }
        }

        if (event.key === 'Enter' && !event.shiftKey) {
          event.stopPropagation();
          event.preventDefault();
          textAreaRef.current?.blur();
        }
      },
      [allowNewLines],
    );

    const handleFocus = useCallback<Required<TextareaProps>['onFocus']>((event) => {
      const textareaValue = event.target.value;
      event.target.setSelectionRange(textareaValue.length, textareaValue.length);
    }, []);

    return (
      <div className={tailwindMerge('relative flex flex-1 flex-col', className)}>
        <Textarea
          ref={textAreaRef}
          aria-label={label}
          autoFocus
          className={tailwindMerge(
            'resize-none overflow-x-hidden px-2 py-0',
            allowNewLines ? 'whitespace-pre-wrap' : 'whitespace-nowrap',
            inputClassName,
          )}
          onBlur={handleBlur}
          onFocus={handleFocus}
          hasError={!!errorToShow}
          onKeyDown={handleKeyDown}
          onKeyUp={handleEscOnKeyUp}
          value={value}
          onChange={onInputChange}
          {...restProps}
        />
        <InlineError
          data-testid={restProps['data-testid'] ? `${restProps['data-testid']}-error` : 'INLINE_INPUT_ERROR'}
          error={errorToShow}
        />
      </div>
    );
  },
);

InlineInput.displayName = 'InlineInput';
