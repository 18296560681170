import { FormikField } from '@air/formik-field';
import { Button } from '@air/primitive-button';
import { Color } from '@air/primitive-color-picker';
import { Input } from '@air/primitive-input';
import { Label } from '@air/primitive-label';
import { Textarea } from '@air/primitive-textarea';
import { Form, Formik, FormikConfig } from 'formik';
import { object, string } from 'yup';

import { getLibraryTitleValidation } from '~/components/LibraryBeta/LibraryPage/utils/libraryTitleValidationSchema';
import {
  LibraryPrivacySettings,
  LibraryPrivacySettingsValue,
} from '~/components/LibraryBeta/LibraryPrivacySettings/LibraryPrivacySettings';
import { UpdateLibraryIcon } from '~/components/LibraryBeta/LibrarySettingsModal/components/UpdateLibraryIcon';

const VALIDATION_SCHEMA = object().shape({
  description: string(),
  privacy: string().oneOf(['invite-only', 'public-to-workspace', 'request-to-join']).required(),
  title: getLibraryTitleValidation({ requiredText: 'Library name is required.' }),
});

export type UpdateLibraryDetailsValues = {
  color?: Color;
  description?: string;
  icon?: string;
  privacy: LibraryPrivacySettingsValue;
  title: string;
};

export type UpdateLibraryDetailsProps = Required<
  Pick<FormikConfig<UpdateLibraryDetailsValues>, 'initialValues' | 'onSubmit'>
> & {
  colors?: Color[];
  isDisabled?: boolean;
  isSubmitting?: boolean;
};

export const UpdateLibraryDetails = ({
  colors,
  initialValues,
  isDisabled,
  isSubmitting,
  onSubmit,
}: UpdateLibraryDetailsProps) => {
  return (
    <Formik<UpdateLibraryDetailsValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={VALIDATION_SCHEMA}
    >
      {({ dirty, isValid }) => (
        <Form data-testid="UPDATE_LIBRARY_DETAILS_FORM">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <Label htmlFor="update-library-details-title" size="small">
                Name
              </Label>
              <div className="flex items-center gap-2">
                <UpdateLibraryIcon className="shrink-0" colors={colors} />
                <FormikField
                  isLabelHidden={true}
                  className="w-full"
                  component={<Input disabled={isDisabled} />}
                  id="update-library-details-title"
                  label="Title"
                  name="title"
                />
              </div>
            </div>

            <FormikField
              id="create-library-form-description"
              label="Description"
              name="description"
              component={<Textarea placeholder="What is this library used for" />}
            />

            <LibraryPrivacySettings name="privacy" />
          </div>

          <div className="-mx-8 -mb-7 mt-8 flex items-center justify-end border-t border-t-grey-5 px-8 py-4">
            <Button
              appearance="filled"
              disabled={!dirty || isDisabled || isSubmitting || !isValid}
              color="blue"
              isLoading={isSubmitting}
              size="large"
              type="submit"
            >
              Update library
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
