import { ChevronDown } from '@air/next-icons';
import { memo } from 'react';

import { ProgressingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { AssetRestorationTask, GetInProgressMetadata } from '~/store/tasks/types';

export type RestoreAssetTaskInProgressPanelItemProps = {
  metadata: Pick<GetInProgressMetadata<AssetRestorationTask>, 'assets'>;
  onCancel: () => void;
};

export const RestoreAssetTaskInProgressPanelItem = memo(
  ({ onCancel, metadata: { assets } }: RestoreAssetTaskInProgressPanelItemProps) => {
    return (
      <PaneItemTW
        avatar={<ProgressingAvatar />}
        title={
          <StatusTrackingTitle>
            {assets.length === 1 ? (
              <>Restoring asset {<strong>{assets[0].title}</strong>}</>
            ) : (
              `Restoring ${assets.length} assets`
            )}
          </StatusTrackingTitle>
        }
        buttons={<PaneButton label="Hide" Icon={ChevronDown} onClick={onCancel} />}
      />
    );
  },
);

RestoreAssetTaskInProgressPanelItem.displayName = 'RestoreAssetTaskInProgressPanelItem';
